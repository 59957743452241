<template>
  <b-row>
    <b-col md="12" lg="12">
      <h1>{{ $t('dashboards.old-advanced-search') }}</h1>
      <div>
        <b-row>
          <b-col>
            <label
              >Please enter a search term (minimum 2 characters) and click the
              Search button to begin a search.</label
            >
          </b-col>
        </b-row>

        <b-row class="row">
          <b-col xl="4" lg="4" md="4">
            <b-form-group>
              <b-form-input
                type="text"
                size="lg"
                v-model.trim="searchText"
                v-on:keyup.enter="handleSearch()"
              >
              </b-form-input>
            </b-form-group>
          </b-col>

          <b-col xl="2" lg="2" md="2">
            <b-button
              :disabled="searchButtonIsDisabled"
              variant="primary"
              @click.self="handleSearch()"
              v-on:keyup.enter="handleSearch()"
              >Search</b-button
            >
          </b-col>

          <b-col
            class="text-right result-count"
            offset-xl="5"
            offset-lg="5"
            xl="1"
            lg="1"
            offset-md="4"
            md="2"
          >
            <label v-if="dataCount > 0">{{ dataCount }} Results</label>
          </b-col>
        </b-row>
      </div>

      <b-overlay
        :show="gridLoading"
        variant="transparent"
        blur="5px"
        opacity="0.9"
      >
        <b-card class="mb-4">
          <vuetable
            ref="vuetable"
            :api-mode="false"
            :fields="fields"
            :data-total="dataCount"
            :data-manager="dataManager"
            :css="css.table"
            @vuetable:cell-rightclicked="rightClicked"
            :noDataTemplate="
              'Please enter a valid search term (company name, contact name, city, etc.) and click Search.'
            "
          >
            <template slot="jsonData" slot-scope="props">
              <JsonObjectFormatter
                :jsonData="props.rowData.associatedJSONData"
                :highlightText="searchText"
              >
              </JsonObjectFormatter>
            </template>
            <template slot="matchingValue" slot-scope="props">
              <text-highlight
                :queries="searchText ? searchText : ''"
                highlightClass="highlight-word"
                >{{ props.rowData.matchingValue }}</text-highlight
              >
            </template>
          </vuetable>
        </b-card>
      </b-overlay>
      <v-contextmenu ref="contextmenu">
        <v-contextmenu-item @click="onContextMenuAction('view')">
          <i class="iconsminds-3d-eyeglasses" />
          <span>View</span>
        </v-contextmenu-item>
      </v-contextmenu>
    </b-col>
  </b-row>
</template>

<script>
import CssTableConfig from '../../../constants/VuetableBootstrapconfig'
import CompanyMixin from '../../../mixins/CompanyMixin.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import JsonObjectFormatter from '../../../components/Common/Company/JsonObjectFormatter.vue'
import TextHighlight from 'vue-text-highlight'

export default {
  props: ['companyID'],
  components: {
    vuetable: Vuetable,
    JsonObjectFormatter: JsonObjectFormatter,
    'text-highlight': TextHighlight
  },
  mixins: [CompanyMixin],
  validations: {},
  data () {
    return {
      dataCount: 0,
      css: CssTableConfig,
      gridLoading: false,
      searchText: '',
      fields: [
        {
          name: 'companyID',
          title: 'Company ID',
          titleClass: '',
          dataClass: 'text-muted',
          width: '7%'
        },
        {
          name: 'companyLegalName',
          title: 'Company Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: 'relatedEntity',
          title: 'Result Type',
          titleClass: '',
          dataClass: 'text-muted',
          width: '10%'
        },
        {
          name: 'matchingColumnName',
          title: 'Matching Column Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: '__slot:matchingValue',
          title: 'Matching Value',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: '__slot:jsonData',
          title: 'Data Associated with Match',
          titleClass: '',
          dataClass: 'text-muted',
          width: '20%'
        }
      ]
    }
  },
  mounted () {
    const urlParams = new URL(location.href).searchParams
    this.searchTerm = urlParams.get('search')
    if (this.searchTerm) {
      this.searchText = this.searchTerm.toString()
      this.search(this.searchTerm.toString())
    }
  },
  methods: {
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
    },
    onContextMenuAction (action) {
      var rightClickedItem = this.rightClickedItem
      switch (action) {
        case 'view': {
          var routeData = {}
          const {
            CompanyAddressID = '',
            PhoneID = '',
            ContactID = ''
          } = JSON.parse(rightClickedItem.associatedJSONData || '{}')
          if (rightClickedItem.relatedEntity.trim() === 'Company') {
            routeData = this.$router.resolve({
              name: 'EditCompany',
              params: { companyID: rightClickedItem.companyID }
            })
          } else if (
            rightClickedItem.relatedEntity.trim() === 'Company Address'
          ) {
            routeData = this.$router.resolve({
              name: 'CompanyAddresses',
              params: { companyID: rightClickedItem.companyID },
              query: { addressID: CompanyAddressID }
            })
          } else if (
            rightClickedItem.relatedEntity.trim() === 'Company Phone'
          ) {
            routeData = this.$router.resolve({
              name: 'companyPhoneGrid',
              params: { companyID: rightClickedItem.companyID },
              query: { phoneID: PhoneID }
            })
          } else if (
            rightClickedItem.relatedEntity.trim() === 'Contact Phone'
          ) {
            routeData = this.$router.resolve({
              name: 'CompanyContact',
              params: { companyID: rightClickedItem.companyID },
              query: { contactID: ContactID }
            })
          } else if (rightClickedItem.relatedEntity.trim() === 'Contact') {
            routeData = this.$router.resolve({
              name: 'CompanyContact',
              params: { companyID: rightClickedItem.companyID },
              query: { contactID: ContactID }
            })
          }

          window.open(routeData.href, '_blank')
          break
        }
        default:
          break
      }
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },
    dataManager (sortOrder, pagination) {
      const data = this.searchResults
      return {
        data
      }
    },
    async search (searchTerm = null) {
      const term = searchTerm || this.searchText
      if (term.length > 1) {
        this.gridLoading = true
        await this.getAdvancedSearchResults(term).then(_ => {
          this.dataCount = this.searchResults.length
          this.$refs.vuetable.refresh()
          this.gridLoading = false
        })
      }
    },
    handleSearch () {
      this.search(this.searchText.toString())
      const urlParams = new URLSearchParams()
      urlParams.set('search', this.searchText)
      history.replaceState(null, null, '?' + urlParams.toString())
    }
  },
  computed: {
    searchButtonIsDisabled () {
      return this.searchText.length <= 1
    }
  }
}
</script>
<style>
.result-count {
  margin-top: 25px;
}
.highlight-word {
  font-weight: bold;
}
</style>
